@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;500;700&display=swap');
@import url('https://cdn.jsdelivr.net/npm/@mdi/font@6.3.95/css/materialdesignicons.min.css');

html,
body {
  padding: 0;
  margin: 0;
  font-family: 'Roboto', sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

:root {
  --white: #fff;
  --black: #000;
  --main-bg-color: #06112e;
  --secondary-bg-color: #fafafa;
  --active-bg-color: #4fa5df14;
  --input-bg-color: #0b18380a;
  --input-border-color: #0b18386b;
  --text-dark-color: #0b1838;
  --tab-border-color: #e2e3e7;
  --primary-color: #1278bc;
  --primary-color-faded: #1278bc14;
  --secondary-color: #003077;
  --input-text-color: #878c99;
  --text-color: #06112e;
  --placeholder-color: #878c99;
  --radio-size: 20px;
  --gray-color: #aeb2bd;
  --success-color: #4caf50;
  --radio: #7c96b2;
  --radio-checked: #4f29f0;
  --chat-color: #6683ad;
  --selection-color: rgba(79, 165, 223, 0.08);
  --subtitle-color: rgba(0, 0, 0, 0.6);
  --subtitle-grey-color: #00000099;
  --verification-green-box: #ebffec;
  --text-dark-second: #21212199;
  --chip-completed: #dcf7dd;
  --chip-draft: #fff7e0;
  --chip-reject: #f44336;
  --error-dark: #e31b0c;
}
html,
body,
#root,
#__next {
  height: 100%;
}

.mt52 {
  margin-top: 52px;
}

.MuiTypography-h6,
.MuiTypography-h5,
.MuiTypography-h4 {
  font-weight: 500;
}
.MuiTypography-subtitle1 {
  font-size: 16px;
  font-style: normal;
  letter-spacing: 0.15px;
}

.MuiTypography-body1 {
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.15px;
}

.MuiRadio-root + .MuiFormControlLabel-label {
  color: var(--text-color);
}

.MuiCheckbox-root + .MuiFormControlLabel-label {
  color: var(--text-color);
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  margin: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.MuiFilledInput-root.Mui-disabled {
  background: #0b18380a;
}

.SnackbarItem-variantError {
  background: #feeceb !important;
}

.SnackbarItem-variantError .SnackbarItem-message {
  color: #621b16 !important;
}

.SnackbarItem-variantInfo {
  background: #073232 !important;
}

.SnackbarItem-variantInfo .SnackbarItem-message {
  color: #ffffff !important;
}

.no-print {
  display: none;
}
div[role='presentation'] .MuiBackdrop-root {
  z-index: 0;
}

#olark-wrapper .olark-launch-button-wrapper {
  z-index: 40 !important;
}
